@font-face {
	font-family: "LexendRegular";
	src: local("LexendRegular"),
		url("./fonts/Lexend-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "LexendMedium";
	src: local("LexendMedium"),
		url("./fonts/Lexend-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "LexendBold";
	src: local("LexendBold"), url("./fonts/Lexend-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "LexendLight";
	src: local("LexendLight"), url("./fonts/Lexend-Light.ttf") format("truetype");
}

@font-face {
	font-family: "InterRegular";
	src: local("InterRegular"),
		url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "InterMedium";
	src: local("InterMedium"), url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "InterBold";
	src: local("InterBold"), url("./fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "InterLight";
	src: local("InterLight"), url("./fonts/Inter-Light.ttf") format("truetype");
}

* {
	position: relative;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: InterRegular;
	font-size: 1rem;
	color: #050911;
	background-color: #fff;
}

a {
	text-decoration: none;
}

a:not(.decorated) {
	color: #050911;
}

textarea {
	resize: none;
}

.test {
	border: 1px solid red;
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left;
	justify-content: left;
}

.flex-row-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

.full-height {
	height: 100% !important;
}

.full {
	width: 100% !important;
}

.half {
	width: 50% !important;
}

.fit {
	width: fit-content !important;
}

.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.block {
	display: block !important;
}

.border {
	border: 1px solid rgba(229, 231, 235, 0.5);
}

.border-top {
	border-top: 1px solid rgba(229, 231, 235, 0.5);
}

.border-left {
	border-left: 1px solid rgba(229, 231, 235, 0.5);
}

.border-bottom {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.border-right {
	border-right: 1px solid rgba(229, 231, 235, 0.5);
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.full-page {
	min-height: 100vh !important;
}

.text-regular-sora {
	font-family: SoraRegular;
}

.text-medium-sora {
	font-family: SoraMedium;
}

.text-bold-sora {
	font-family: SoraBold;
}

.text-light-sora {
	font-family: SoraLight;
}

.text-regular-inter {
	font-family: InterRegular;
	/* font-weight: normal; */
}

.text-medium-inter {
	font-family: InterMedium;
	/* font-weight: 600; */
}

.text-bold-inter {
	font-family: InterBold !important;
	/* font-weight: 700; */
}

.text-light-lexend {
	font-family: LexendLight;
}

.text-regular-lexend {
	font-family: LexendRegular;
	/* font-weight: normal; */
}

.text-medium-lexend {
	font-family: LexendMedium;
	/* font-weight: 600; */
}

.text-bold-lexend {
	font-family: LexendBold;
	/* font-weight: 700; */
}

.text-regular-rubik {
	font-family: RubikRegular;
	/* font-weight: normal; */
}

.text-medium-rubik {
	font-family: RubikMedium;
	/* font-weight: 600; */
}

.text-bold-rubik {
	font-family: RubikBold !important;
	/* font-weight: 700; */
}

.text-light-inter {
	font-family: InterLight;
}

.text-white {
	color: #ffffff !important;
}

.text-light {
	color: #828488 !important;
}

.text-blue {
	color: #3c57cc !important;
}

.text-blue-dark {
	color: #0d163f !important;
}

.text-default {
	color: #050911 !important;
}

.text-red {
	color: #ff3819 !important;
}

.title-xxl {
	font-size: 3.75rem;
}

.title-lg {
	font-size: 2.5rem;
}

.title-xl {
	font-size: 3rem;
}

.title-md {
	font-size: 2rem;
}

.text-xl {
	font-size: 1.5rem !important;
}

.text-lg {
	font-size: 1.125rem !important;
}

.text-md {
	font-size: 1rem !important;
}

.text-sm {
	font-size: 0.875rem !important;
}

.label-md {
	font-size: 0.75rem !important;
}

.label-sm {
	font-size: 0.563rem !important;
}

.upper {
	text-transform: uppercase;
}

.opaque {
	opacity: 1 !important;
}

.opacity-1 {
	opacity: 0.1 !important;
}

.opacity-3 {
	opacity: 0.3 !important;
}

.opacity-5 {
	opacity: 0.5 !important;
}

.opacity-7 {
	opacity: 0.7 !important;
}

.p-20 {
	padding: 20px;
}

.p-30 {
	padding: 30px;
}

.p-50 {
	padding: 50px;
}

.p-60 {
	padding: 60px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-50 {
	margin-bottom: 50px;
}

.input-lg {
	height: 55px !important;
}

.btn-lg {
	height: 55px !important;
}

.btn-md {
	height: 48px !important;
	padding-left: 20px;
	padding-right: 20px;
}

.btn-sm {
	height: 38px !important;
	padding-left: 20px;
	padding-right: 20px;
}

.btn-default {
	background-color: #3c57cc !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-default:hover {
	background-color: rgba(60, 87, 204, 0.9) !important;
}

.btn-default:active {
	background-color: #334fcb !important;
}

.btn-default-outlined {
	background-color: transparent !important;
	border: 2px solid #3c57cc !important;
	color: #3c57cc !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-default-outlined:hover {
	background-color: rgba(60, 87, 204, 0.9) !important;
	color: #ffffff !important;
}

.btn-default-outlined:active {
	background-color: #334fcb !important;
	color: #ffffff !important;
}

.btn-gray {
	background-color: #f7f8fc !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-gray:hover {
	background-color: #eeeff1 !important;
}

.btn-gray:active {
	background-color: #dfe0e1 !important;
}

.btn-gray-dark {
	background-color: #e0e1e3 !important;
	color: #050911 !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-gray-dark:hover {
	background-color: #d3d3d5 !important;
}

.btn-gray-dark:active {
	background-color: rgb(195, 195, 196) !important;
}

.btn-danger {
	background-color: #ff3819 !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-danger:hover {
	background-color: rgba(255, 56, 25, 0.9) !important;
}

.btn-danger:active {
	background-color: #e02f14 !important;
}

.bg-cover {
	background-size: cover !important;
	background-position: center !important;
}

.bg-default {
	background-color: #0d163f !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.bg-gray {
	background-color: #fafbfd;
}

.bg-black {
	background-color: #050911;
}

.bg-blue {
	background-color: #3c57cc !important;
}

.rounded-8 {
	border-radius: 8px !important;
}

.rounded-4 {
	border-radius: 4px !important;
}

.content {
	min-height: 100vh;
	padding-top: 100px;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 4;
	height: 100px;
}

.main-nav-item {
	margin: 0 15px;
}

.nav-toggle {
	height: 60px;
	cursor: pointer;
	display: none;
	width: 48px;
}

.fries > div {
	transition: 0.3s;
}

.mid-fry {
	width: 24px;
	height: 2px;
	float: right;
	background-color: #3c57cc;
}

.top-fry {
	position: absolute;
	width: 24px;
	height: 2px;
	top: -8px;
	right: 0;
	opacity: 1 !important;
	background-color: #3048b0;
}

.bottom-fry {
	position: absolute;
	width: 24px;
	height: 2px;
	bottom: -8px;
	right: 0;
	background-color: #3048b0;
}

.fries.active .mid-fry {
	opacity: 0;
}

.fries.active .top-fry {
	transform: rotate(40deg);
	top: 0;
}

.fries.active .bottom-fry {
	transform: rotate(-40deg);
	width: 24px;
	top: 0;
}

.nav-sm {
	position: fixed;
	top: -100vh;
	left: 0;
	height: calc(100vh - 60px);
	width: 100%;
	transition: 0.4s ease-in;
	z-index: 3;
	padding-top: 20px;
}

.nav-sm.active {
	top: 70px;
}

.nav-sm-action {
	position: absolute;
	left: 0;
	bottom: 15px;
}

.nav-panel {
	position: fixed;
	bottom: 100vh !important;
	right: 0;
	height: calc(100vh - 70px);
	width: 100%;
	z-index: 3;
	transition: 0.3s ease-in;
	padding: 30px 0;
}

.nav-panel .nav-link {
	margin-bottom: 20px;
}

.nav-panel-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	padding-bottom: 23px;
}

.nav-panel-btn {
	height: 55px;
	width: 100%;
}

.banner {
	height: calc(100vh + 250px);
	overflow: hidden;
	background: rgb(13, 22, 63);
	background: linear-gradient(
		180deg,
		rgba(13, 22, 63, 1) 20%,
		rgba(60, 68, 111, 1) 80%
	);
}

.banner-content {
	padding-top: 160px;
}

.banner-btn {
	width: 200px;
}

.banner-screen-container {
	position: absolute;
	bottom: -80px;
	left: 0;
}

.banner-screen {
	height: 700px;
	width: 80%;
	background: #f5f5f5;
	border-radius: 30px;
	background-size: cover !important;
	background-position: center !important;
}

.sub-screen-1 {
	position: absolute;
	left: -50px;
	top: 50px;
	height: 100px;
	width: 100px;
	border-radius: 50%;
	background: #fff;
	box-shadow: 5px 5px 67px -30px rgba(0, 0, 0, 0.39);
	-webkit-box-shadow: 5px 5px 67px -30px rgba(0, 0, 0, 0.39);
	-moz-box-shadow: 5px 5px 67px -30px rgba(0, 0, 0, 0.39);
}

.sub-screen-2 {
	position: absolute;
	left: -50px;
	bottom: 150px;
	height: 130px;
	width: 150px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 5px 5px 67px -30px rgba(0, 0, 0, 0.39);
	-webkit-box-shadow: 5px 5px 67px -30px rgba(0, 0, 0, 0.39);
	-moz-box-shadow: 5px 5px 67px -30px rgba(0, 0, 0, 0.39);
}

.sub-screen-3 {
	position: absolute;
	right: -80px;
	top: 70px;
	height: 230px;
	width: 390px;
	border-radius: 20px;
	background: #fff;
	box-shadow: -5px 5px 67px -30px rgba(0, 0, 0, 0.39);
	-webkit-box-shadow: -5px 5px 67px -30px rgba(0, 0, 0, 0.39);
	-moz-box-shadow: -5px 5px 67px -30px rgba(0, 0, 0, 0.39);
}

.sub-screen-4 {
	position: absolute;
	right: -80px;
	bottom: 120px;
	height: 50px;
	width: 190px;
	border-radius: 5px;
	background: #fff;
	box-shadow: -5px 5px 67px -30px rgba(0, 0, 0, 0.39);
	-webkit-box-shadow: -5px 5px 67px -30px rgba(0, 0, 0, 0.39);
	-moz-box-shadow: -5px 5px 67px -30px rgba(0, 0, 0, 0.39);
}

.feature {
	padding: 120px 0;
}

.feature-img-container {
	background-color: #dbe1f1;
	/* width: 550px; */
	padding-top: 100%;
	border-radius: 20px;
	overflow: hidden;
}

.feature-img {
	position: absolute;
	bottom: 0;
	height: 80%;
	width: 90%;
	background-color: #0066ff26;
}

.feature-img.left {
	right: 0;
	border-radius: 20px 0 20px 0;
	box-shadow: -5px 5px 50px -30px rgba(0, 0, 0, 0.39);
	-webkit-box-shadow: -5px 5px 50px -30px rgba(0, 0, 0, 0.39);
	-moz-box-shadow: -5px 5px 50px -30px rgba(0, 0, 0, 0.39);
}

.feature-img.right {
	left: 0;
	border-radius: 0 20px 0 20px;
	box-shadow: 5px 5px 50px -30px rgba(0, 0, 0, 0.39);
	-webkit-box-shadow: 5px 5px 50px -30px rgba(0, 0, 0, 0.39);
	-moz-box-shadow: 5px 5px 50px -30px rgba(0, 0, 0, 0.39);
}

.feature-content {
	margin-left: 20px;
}

.sub-feature-check {
	margin-right: 10px;
	max-width: 30px !important;
	width: 30px !important;
}

.btn-wide {
	width: 180px;
}

.feature-title,
.feature-desc {
	padding-right: 100px !important;
}

.sub-feature p {
	padding-right: 100px;
}

.feature-summary-container,
.faq-preview-container,
.cta-container,
.contact-cta-container,
.pricing-banner,
.faq-banner,
.contact-banner,
.privacy-banner,
.pricing-inclusions,
.platform-preview {
	padding-bottom: 120px;
}

.feature-summary {
	padding: 80px 80px 30px 80px;
	border-radius: 40px;
}

.feature-summary-btn {
	width: 180px;
}

.feature-summary-icon {
	margin-right: 5px;
}

.accordion-item {
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
	border-bottom: 1px solid #0509111a !important;
	outline: none !important;
	box-shadow: none !important;
	border-radius: 0 !important;
}

.accordion-item button {
	padding: 20px 0 !important;
	outline: none !important;
	box-shadow: none !important;
	background-color: transparent !important;
}

.cta-content {
	max-width: 900px;
}

.cta-btn {
	width: 400px;
	height: 80px;
}

.contact-cta {
	border-radius: 20px;
}

.footer {
	padding: 30px 0;
}

.footer-link {
	margin-left: 20px;
}

.copyright {
	margin-left: 20px;
}

.page-banner {
	padding-top: 60px;
}

.feature-page-content {
	padding: 60px 0 100px 0;
}

.feature-page-item {
	padding: 80px;
	border-radius: 40px;
	background-color: #f1f3fa;
}

.pricing-value {
	font-size: 7.5rem;
	line-height: 7.5rem;
}

.contact-content {
	resize: none;
	height: 165px;
}

.feature-sm {
	display: none;
}

.msisdn-img {
	width: 80%;
	border-radius: 20px;
}

.verify-img {
	width: 80%;
	border-radius: 20px;
}

.mfa-img {
	width: 90%;
	border-radius: 20px;
}

.secure-img {
	width: 100%;
}

.pricing-content {
	background-color: #f9fafe;
}

.pricing-tier {
	box-shadow: rgba(0, 44, 155, 0.15) 0px 6px 20px -6px;
	height: 100%;
	padding-bottom: 100px;
}

.tier-btn-container {
	position: absolute;
	left: 0;
	bottom: 0;
}

.platform-preview-logo {
	height: 80px;
}

.password-preview-logo {
	height: 120px;
	border-radius: 8px;
}

.platform-preview-item {
	height: 100px;
	width: 100%;
	/* outline: 1px solid rgba(229, 231, 235, 0.5); */
}

.password-preview-item {
	height: 200px;
	width: 100%;
	/* outline: 1px solid rgba(229, 231, 235, 0.5); */
}

.platform-logo {
	height: 50px;
	margin-right: 7px;
}

.platform-item:hover {
	cursor: pointer;
	background-color: #f1f3fa;
}

.pricing-currency {
	width: 150px;
}

.transition-preview {
	padding: 100px 0;
	margin-bottom: 100px;
}

/* OTHER SCREENS */

@media screen and (max-width: 1280px) {
	.banner {
		height: calc(100vh + 150px);
	}

	.banner-screen-container {
		bottom: -80px;
	}

	.banner-screen {
		height: 600px;
		width: 80%;
		background: #f5f5f5;
		border-radius: 30px;
		background-position: -60px center !important;
	}

	.platform-preview-logo {
		height: 60px;
	}

	.password-preview-logo {
		height: 80px;
	}

	.platform-preview-item {
		height: 80px;
	}
}

@media screen and (max-width: 992px) {
	.nav-lg {
		display: none;
	}

	.nav-sm {
		display: flex;
	}

	.nav-toggle {
		display: inherit;
	}

	.banner {
		height: fit-content;
		padding-bottom: 100px;
	}

	.banner-screen-container {
		display: none;
	}

	.banner-content {
		text-align: center;
	}

	.banner-title {
		padding-left: 50px;
		padding-right: 50px;
	}

	.feature {
		padding: 50px 0;
	}

	.feature-img-container {
		margin-bottom: 30px;
	}

	.feature-content {
		margin-left: 0;
		padding: 0;
	}

	.feature-title {
		padding-right: 80px !important;
	}

	.feature-desc {
		padding-right: 0 !important;
	}

	.sub-feature p {
		padding-right: 20px;
	}

	.feature-btn {
		width: 100% !important;
	}

	.feature-summary {
		padding: 30px !important;
		border-radius: 20px;
	}

	.feature-summary-title {
		padding-right: 50px;
		margin-bottom: 30px;
	}

	.summary-btn-container {
		-webkit-justify-content: left;
		justify-content: left;
	}

	.feature-lg {
		display: none;
	}

	.feature-sm {
		display: inherit;
	}

	.contact-cta {
		padding: 30px;
	}

	.footer-logo-container {
		margin-bottom: 30px;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
	}

	.copyright {
		margin: 0;
	}

	.footer-link {
		margin: 0 10px !important;
	}

	.footer-logo {
		margin-bottom: 30px;
	}

	.footer-links-container {
		-webkit-justify-content: center;
		justify-content: center;
	}

	.feature-page-desc {
		margin-bottom: 30px !important;
	}

	.pricing-inclusion-title {
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 768px) {
	.banner-title {
		font-size: 2.5rem;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 20px !important;
	}

	.banner-desc {
		font-size: 1rem !important;
		margin-bottom: 40px;
	}

	.banner-btn {
		width: 100% !important;
	}

	.feature-title {
		padding-right: 0 !important;
		font-size: 1.6rem;
		margin-bottom: 5px;
	}

	.feature-desc,
	.feature-page-desc {
		font-size: 0.875rem !important;
	}

	.feature-check {
		height: 25px !important;
		width: 25px !important;
	}

	.sub-feature-text {
		font-size: 0.875rem !important;
	}

	.feature-summary,
	.contact-cta {
		padding: 20px !important;
	}

	.feature-summary-title {
		padding-right: 0;
		font-size: 1.7rem;
		line-height: 2.8rem;
	}

	.contact-cta-title {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.contact-cta-btn a {
		width: 100%;
	}

	.feature-summary-btn {
		width: 100% !important;
	}

	.feature-summary-label {
		font-size: 1rem !important;
	}

	.feature-summary-desc,
	.cta-desc,
	.see-pricing {
		font-size: 0.875rem !important;
	}

	.platforms-title,
	.faq-title,
	.cta-title {
		font-size: 1.8rem;
		text-align: center;
		line-height: 2.5rem;
	}

	.platforms-title {
		margin-bottom: 30px !important;
	}

	.faq-question button {
		font-size: 0.875rem !important;
	}

	.main-nav {
		height: 70px;
	}

	.content {
		padding-top: 70px;
	}

	.nav-logo {
		width: 110px;
	}

	.feature-summary-item {
		margin-bottom: 30px !important;
	}

	.cta-btn {
		width: 100%;
		height: 52px;
		font-size: 1.2rem !important;
	}

	.contact-cta-text {
		margin-bottom: 30px;
	}

	.contact-cta-btn button {
		width: 100%;
	}

	.btn-wide {
		width: 100%;
	}

	.feature-page-item {
		padding: 20px;
		border-radius: 20px;
	}

	.pricing-value {
		font-size: 6rem;
	}

	.platform-label {
		font-size: 0.8rem;
	}

	.platform-name {
		font-size: 0.875rem !important;
	}

	.platform-logo {
		height: 40px;
		margin-right: 3px;
	}

	.footer-link {
		font-size: 0.75rem !important;
	}

	.platforms-preview-btn {
		font-size: 1rem !important;
	}

	.mobile-full {
		width: 100%;
	}

	.pricing-title,
	.contact-title {
		font-size: 2rem;
	}

	.pricing-inclusion-title {
		font-size: 1.8rem !important;
	}

	.pricing-banner,
	.pricing-inclusion {
		padding-bottom: 50px;
	}
}
